<template>
  <v-container
    fluid
    v-if="renderizacaoLiberada"
  >
    <validation-observer
      ref="validationObserver"
      v-slot="{ valid }"
    >
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <primeira-tab
            ref="refPrimeiraTab"
            :relatorio="relatorio"
          >
            <template v-slot:footer>
              <v-btn
                id="btn-save-relatorio-modelo"
                color="primary"
                style="float: right"
                class="mr-0"
                @click="salvar()"
                :disabled="!valid"
                :loading="loading"
              >
                Salvar
              </v-btn>
            </template>
          </primeira-tab>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <segunda-tab
            ref="refSegundaTab"
            :relatorio="relatorio"
            @reset:error-messages="$refs.validationObserver.reset()"
            @change-parametros:adicionado="
              $refs.refPrimeiraTab.adicionarParametroSQL($event)
            "
            @change-parametros:removido="
              $refs.refPrimeiraTab.removerParametroSQL($event)
            "
          />
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import RelatoriosModelosService from '@/services/RelatoriosModelosService';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [tourMixins],

  components: {
    PrimeiraTab: () =>
      import('@/components/power-ups/relatorios-modelos/PrimeiraTab'),
    SegundaTab: () =>
      import('@/components/power-ups/relatorios-modelos/SegundaTab')
  },

  data: () => ({
    loading: false,
    relatorio: {}
  }),

  computed: {
    renderizacaoLiberada() {
      if (!this.$route.params.id) return true;

      return this.$route.params.id && JSON.stringify(this.relatorio) !== '{}'
        ? true
        : false;
    },

    arrTour() {
      let tour = [
        {
          element: '#nome-rel-m',
          intro: 'Aqui você deverá escolher um nome para seu relatório modelo.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#grupo-rel-m',
          intro:
            'Aqui você deverá escolher o grupo que seu relatório modelo está associado.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#btn-crud-grupos',
          intro:
            'Clicando sobre este botão você será redirecionado a outra página, onde será possivel visualizar grupos de relatórios, cadastrar e/ou editar.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#exclusivo-rel-m',
          intro:
            'Aqui você deverá informar se este relatório modelo é exlusivo ou seja se ele deve aparecer apenas na empresa conectada ou em todas.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#primeiro-grupo-tabs-rm',
          intro:
            'Nesta seção estará disponível as tabs > <b>CONSULTA SQL</b>: local onde deverá ser escrito a consulta SQL e <b>DESCRIÇÃO</b>: local onde você poderá detalhar e/ou explicar seu relatório modelo.',
          scrollTo: 'tooltip',
          position: 'right'
        }
      ];

      if (this.relatorio.parametros_editaveis !== undefined) {
        tour.push(
          {
            element: '#tabela-visualizacao-parametros-editaveis',
            intro:
              'Nesta tabela será apresentado detalhadamente todos os parâmetros editáveis cadastrados para esse relatório modelo.',
            scrollTo: 'tooltip',
            position: 'left'
          },
          {
            element: '#remove-parametro-editavel',
            intro:
              'Clicando sobre este botão você poderá excluir um parâmetro editável.',
            scrollTo: 'tooltip',
            position: 'left'
          }
        );
      }

      tour.push(
        {
          element: '#segundo-grupo-tabs-rm',
          intro:
            'Nesta seção estará disponível as tabs > <b>FILTRAR POR CAMPO</b>: local onde poderá ser cadastrado parâmetros editáveis com base em uma base de dados, campo e critério predefinido e tab <b>VALOR DO USUÁRIO</b>: local onde poderá ser cadastrado parâmetros editáveis com base em um tipo de dados e valor.',
          scrollTo: 'tooltip',
          position: 'left'
        },
        {
          element: '#btn-save-relatorio-modelo',
          intro:
            'Após seleção dos critérios anteriores clique aqui para salvar seu relatório modelo.',
          scrollTo: 'tooltip',
          position: 'left'
        }
      );

      return tour;
    }
  },

  created() {
    this.$route.params.id
      ? this.getRelatorioModelo(this.$route.params.id)
      : this.iniciarTourPagina();
  },

  methods: {
    getRelatorioModelo(id) {
      RelatoriosModelosService.getRelatorioModelo(id)
        .then((response) => {
          let rData = response.data.data;
          this.relatorio = {
            nome: rData.nome,
            grupoId: rData.grupo_relatorio.id,
            consultaSQL: rData.codigo_sql,
            descricao: rData.descricao ? rData.descricao : '',
            parametros_editaveis: rData.parametros_editaveis,
            exclusivo: rData.exclusivo ? 'Sim' : 'Não'
          };
          this.iniciarTourPagina();
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar relatorio modelo.', '', {
            position: 'topRight'
          })
        );
    },

    salvar() {
      let data = { id: this.$route.params.id ? this.$route.params.id : null };
      data = {
        ...data,
        ...this.$refs.refPrimeiraTab.exportCampos(),
        ...this.$refs.refSegundaTab.exportCampos()
      };

      (data.id = this.$route.params.id ? this.$route.params.id : null),
        (this.loading = true);

      RelatoriosModelosService.save(data)
        .then(() => {
          this.$toast.success('Relatório modelo salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['RELATORIOS_MODELOS_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar relatório modelo.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      this.iniciarTour(this.arrTour);
    }
  }
};
</script>
